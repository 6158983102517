export enum ClaimFilter {
  ALL = '',
  DRAFT = 'DRAFT',
  AWAITING_REVIEW = 'AWAITING_REVIEW',
  DECLINED = 'DECLINED',
  AWAITING_INITIAL_TENANTS_RESPONSE = 'AWAITING_INITIAL_TENANTS_RESPONSE',
  AWAITING_FINAL_TENANTS_RESPONSE = 'AWAITING_FINAL_TENANTS_RESPONSE',
  AWAITING_SUPPLIER_RESPONSE = 'AWAITING_SUPPLIER_RESPONSE',
  AWAITING_SUBSTANTIATION = 'AWAITING_SUBSTANTIATION',
  AWAITING_RESPONDENTS = 'AWAITING_RESPONDENTS',
  AWAITING_ARBITRATION = 'AWAITING_ARBITRATION',
  CASE_WITH_ADJUDICATOR = 'CASE_WITH_ADJUDICATOR',
  AWAITING_ARBITRATION_ADMIN_FEE = 'AWAITING_ARBITRATION_ADMIN_FEE',
  ARBITRATED = 'ARBITRATED',
  INSURANCE_CLAIM = 'INSURANCE_CLAIM',
  RESOLVED = 'RESOLVED',
  DEACTIVATED = 'DEACTIVATED',
  IS_PAID = 'IS_PAID',
}

export enum ClaimSort {
  CREATED_AT = 'CREATED_AT',
}
